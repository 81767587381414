$(function() {
    $('a[rel*=leanModal]').leanModal({ top : 200, closeButton: ".modal_close" });
});
$(function() {
    $(".scrollable").scrollable({circular: 0, speed: 300, size: 8});
    $(".scrollabletwo").scrollable({circular: 0, speed: 300, size: 4});
});
$(function(){
    var $first = $("div.caption .price_h").eq(0)
    var $second = $("div.caption .description_h").eq(0)
    setTimeout(function(){
        $first.fadeIn(400)
    },800)
    setTimeout(function(){
        $second.fadeIn(400)
    },1600)
    $('#slides').slides({
        preload: true,
        preloadImage: '/img/loading.gif',
        play: 5000,
        pause: 2500,
        hoverPause: true,
        animationStart: function(current){
            $('.caption').animate({
                bottom:-35
            },500);
            $("div.caption .price_h").hide()
            $("div.caption .description_h").hide()
        },
        animationComplete: function(current){
            $('.caption').animate({
                bottom:0
            },500);
            var $first = $("div.caption .price_h").eq(current - 1)
            var $second = $("div.caption .description_h").eq(current - 1)
            setTimeout(function(){
                $first.fadeIn(400)
            },400)
            setTimeout(function(){
                $second.fadeIn(400)
            },800)

        }
    });
});