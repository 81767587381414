$().ready(function(){
    var driver = 0; // 1 = cookie, 0 = localStorage
    function get(key){
        return driver ? $.cookie(key) : localStorage.getItem(key)
    }
    function set(key,value){
        return driver ? $.cookie(key,value) : localStorage.setItem(key,value)
    }
    // setInterval(function(){
    //     if($.cookie("autocom_like_allow") == "allow") return; // уже показывал
    //     var date = parseInt(new Date().getTime()), // текущее время
    //         key = get("autocom_like") // время последнего посещения
    //     if(key == undefined){
    //         set("autocom_like",date); // если первый раз зашел то задать время
    //     }
    //     if(date - 65000 > parseInt(key)){ // прошло x милисекунд
    //         localStorage.removeItem("autocom_like")
    //         return;
    //     }
    //     if(date - 60000 > parseInt(key)){ // прошло x милисекунд
    //         view_popup('/social/index') // показать окно
    //         $.cookie("autocom_like_allow","allow") // ставить флаг больше не показывать
    //     }
    // },1000); // каждую секунду запускать
})